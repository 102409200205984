import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import EmployPackageList from "../components/EmployPackageList";

class EmployCenterMainNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  componentDidUpdate() {}
  componentDidMount() {
    this.props.SagaApiCall({
      type: "EMPLOY_CENTER_CALL",
      name: "EMPLOY_CENTER_OK",
      option: this.props.option
    });
  }
  componentWillUnmount() {}
  nextListFnc() {
    if (isEmpty(this.props.EmployCenterGet.next_page)) return false;
    this.props.SagaApiCall({
      type: "EMPLOY_CENTER_LIST_CALL",
      name: "EMPLOY_CENTER_OK",
      option: {
        url: this.props.EmployCenterGet.next_page
      }
    });
  }
  render() {
    return (
      <div>
        <EmployPackageList
          name={`${this.props.option.params.list_type}${
            this.props.option.params.view_count
          }${this.props.option.params.current_page}`}
          title={
            isEmpty(this.props.EmployCenterGet.header) ||
            this.props.option.title === "none"
              ? ""
              : this.props.EmployCenterGet.header
          }
          items={
            isEmpty(this.props.EmployCenterGet.items)
              ? []
              : this.props.EmployCenterGet.items
          }
          next_items={
            isEmpty(this.props.EmployCenterGet.next_items)
              ? []
              : this.props.EmployCenterGet.next_items
          }
          more={
            this.props.option.more_button === "none"
              ? ""
              : this.props.option.more_button
          }
          option={isEmpty(this.props.option) ? "" : this.props.option}
          next_page={
            isEmpty(this.props.EmployCenterGet.next_page)
              ? ""
              : this.props.EmployCenterGet.next_page
          }
          listFnc={() => this.nextListFnc()}
        />
      </div>
    );
  }
}

const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
EmployCenterMainNew = connect(
  getRow,
  brandApplyDispatchToProps
)(EmployCenterMainNew);
export default EmployCenterMainNew;
