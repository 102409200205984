import styled, { css } from "react-emotion";

const jp_card_list = css`
  width: 1024px;
  margin-left: -10px;
  & > li {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-top: 0;
    }
  }
`;

const jp_comingsoon_card_list = css`
  width: 1024px;
  margin-left: 0;
  & > li {
    float: left;
    margin-top: 30px;
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-top: 0;
    }
  }
`;

const list_card_type = css`
  overflow: hidden;
  box-sizing: border-box;
  width: 236px;
  height: 256px;
  background: #fff;
  border: 1px solid #e6e6e6;
  & > a:hover {
    text-decoration: none;
    .img_wrap > img {
      transition-duration: 0.2s;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -moz-transition: scale(1.05);
      transform: scale(1.05);
    }
    .card_title {
      text-decoration: underline;
    }
  }
  .card_thumb {
    position: relative;
    .img_wrap {
      position: relative;
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      min-height: 133px;
      padding-top: 56.25%;
      background: #fff;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        margin: 0 auto;
        -webkit-transition: all 0.2s ease-in-out;
        -webkit-transform: scale(1);
        -ms-transition: all 0.2s ease-in-out;
        -ms-transform: scale(1);
        -moz-transition: all 0.2s ease-in-out;
        -moz-transform: scale(1);
        transition: all 0.2s ease-in-out;
        transform: scale(1);
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
  .card_text {
    position: relative;
    padding: 16px;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .logo_wrap {
      float: left;
      display: inline-block;
      overflow: hidden;
      box-sizing: border-box;
      width: 62px;
      height: 62px;
      margin-right: 10px;
      background: #fff;
      border: 1px solid #eee;
      img {
        width: 100%;
        height: auto;
      }
    }
    .card_title {
      overflow: hidden;
      display: block;
      font-size: 16px;
      line-height: 19px;
      color: #444;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .card_summary {
      height: 15px;
      margin-top: 8px;
      font-size: 12px;
      line-height: 15px;
      color: #999;
      word-break: break-all;
    }
    .price {
      display: block;
      padding-top: 8px;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #00c362;
    }
  }
  .card_footer {
    position: relative;
    box-sizing: border-box;
    height: 47px;
    padding: 12px 16px 14px 16px;
    border-top: 1px solid #e6e6e6;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .time_remaining {
      float: left;
      display: inline-block;
      max-width: 80px;
      font-size: 12px;
      color: #999;
      line-height: 24px;
      vertical-align: top;
    }
    .content_config_list {
      float: right;
    }
  }
`;

const detail_card_type = props => css`
  overflow: hidden;
  box-sizing: border-box;
  width: 236px;
  background: #fff;
  border: 1px solid #e6e6e6;
  ${props.is_disabled === true
    ? `.ico_lock_gray {
      display: inline-block;
    }
    .employ_contents:hover {
      cursor: pointer;
    }`
    : `.ico_lock_gray {
      display: none;
    }
    .employ_contents:hover {
      text-decoration: none;
      cursor: pointer;
      .img_wrap > img {
        transition-duration: 0.2s;
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -moz-transition: scale(1.05);
        transform: scale(1.05);
      }
      .card_title {
        text-decoration: underline;
      }
    }`} .card_header {
    position: relative;
    height: 60px;
    padding: 15px 15px 0;
    box-sizing: border-box;
    .logo_wrap {
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      width: 45px;
      height: 45px;
      margin-right: 10px;
      background: #fff;
      border: 1px solid #eee;
      img {
        width: 100%;
        height: auto;
      }
    }
    .page_count {
      position: absolute;
      top: 15px;
      right: 15px;
      display: inline-block;
      font-size: 12px;
      color: #999;
      & > span {
        overflow: hidden;
        display: inline-block;
        width: 1px;
        height: 1px;
        text-indent: 100%;
        white-space: nowrap;
      }
    }
  }
  .card_thumb {
    position: relative;
    height: 132px;
    overflow: hidden;
    .img_wrap {
      position: relative;
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      padding-top: 56.25%;
      background: #fff;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        margin: 0 auto;
        -webkit-transition: all 0.2s ease-in-out;
        -webkit-transform: scale(1);
        -ms-transition: all 0.2s ease-in-out;
        -ms-transform: scale(1);
        -moz-transition: all 0.2s ease-in-out;
        -moz-transform: scale(1);
        transition: all 0.2s ease-in-out;
        transform: scale(1);
        image-rendering: -webkit-optimize-contrast;
      }
      .ico_vod_white {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -23px;
        margin-left: -23px;
        display: block;
        content: "";
        z-index: 10;
        text-indent: 100%;
        white-space: nowrap;
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        content: "";
        z-index: 1;
      }
    }
    .vod_time {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 10;
      padding: 5px 5px 4px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 11px;
      line-height: 1;
    }
  }
  .card_text {
    position: relative;
    height: 102px;
    padding: 15px;
    box-sizing: border-box;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .logo_wrap {
      float: left;
      display: inline-block;
      overflow: hidden;
      box-sizing: border-box;
      width: 45px;
      height: 45px;
      margin-right: 10px;
      background: #fff;
      border: 1px solid #eee;
      img {
        width: 100%;
        height: auto;
      }
    }
    .card_title {
      overflow: hidden;
      display: block;
      padding-top: 5px;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 19px;
      color: #444;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .card_summary {
      height: 34px;
      font-size: 12px;
      line-height: 17px;
      color: #999;
      word-break: break-all;
      overflow: hidden;
    }
  }
  .card_footer {
    position: relative;
    box-sizing: border-box;
    height: 49px;
    padding: 15px;
    border-top: 1px solid #e6e6e6;
    .card_foot_text {
      display: block;
      padding-right: ${props.is_disabled === true ? "20px" : "0"};
      font-size: 12px;
      line-height: 17px;
      color: #00c362;
    }
  }
  .ico_lock_gray {
    position: absolute;
    top: 15px;
    right: 15px;
    text-indent: 100%;
    white-space: nowrap;
  }
`;
const content_config_list = css`
  display: inline-block;
  vertical-align: top;
  & > li {
    position: relative;
    display: inline-block;
    height: 20px;
    min-width: 52px;
    padding-left: 24px;
    margin-left: 19px;
    font-size: 16px;
    line-height: 24px;
    color: #444;
    box-sizing: border-box;
    vertical-align: top;
    &:first-child {
      margin-left: 0;
    }
  }
  .icon {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 100%;
    white-space: nowrap;
  }
  .ico_lecture_vod {
    overflow: hidden;
    display: block;
    width: 18px;
    height: 18px;
  }
  .ico_lecture_report {
    overflow: hidden;
    display: block;
    width: 16px;
    height: 16px;
  }
  .ico_lecture_vod {
    margin-top: -1px;
  }
`;

const DetailCardLi = styled("li")`
  ${detail_card_type};
`;

const pass_goods_list = css`
  width: 1014px;
  margin-left: -5px;
  margin-right: -5px;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
`;

const pass_goods_card = css`
  float: left;
  display: block;
  box-sizing: border-box;
  width: 328px;
  height: 395px;
  padding: 40px 30px 30px;
  margin: 0 5px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  &:nth-of-type(n + 4) {
    margin-top: 10px;
  }
  .goods_card_header {
    overflow: hidden;
    width: 100%;
    height: 31px;
    margin-bottom: 30px;
    .jp_tag {
      margin-top: 3px;
      margin-left: 15px;
      & ~ .goods_title {
        float: left;
        width: auto;
        max-width: calc(100% - 64px);
      }
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  .goods_card_body {
    height: 154px;
    font-size: 16px;
  }
  .goods_card_footer {
    overflow: hidden;
    height: 110px;
  }
  .goods_title {
    display: inline-block;
    width: 100%;
    font-size: 26px;
    color: #00c362;
    line-height: 31px;
    font-weight: bold;
  }
  .goods_desc {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 19px;
    color: #444;
    font-weight: bold;
  }
  .goods_note {
    max-height: 120px;
    font-size: 16px;
    line-height: 30px;
    color: #666;
  }
  .payment_info {
    display: table;
    width: 100%;
    height: 65px;
  }
  .payment_info_row {
    display: table-row;
  }
  .payment_info_item {
    display: table-cell;
    vertical-align: middle;
    &.percent {
      width: 60px;
      font-size: 18px;
      color: #ff8400;
    }
    &.discount {
      width: 95px;
      font-size: 18px;
      color: #999;
    }
    &.price {
      font-size: 24px;
      color: #333;
      text-align: right;
    }
  }
`;

const list_comingsoon_card_type = css`
  overflow: hidden;
  box-sizing: border-box;
  width: 340px;
  height: 90px;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  & > a:hover {
    text-decoration: none;
    .img_wrap > img {
      transition-duration: 0.2s;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -moz-transition: scale(1.05);
      transform: scale(1.05);
    }
    .card_title {
      text-decoration: underline;
    }
  }
  .card_thumb {
    position: relative;
    float: left;
    width: 160px;
    height: 90px;
    .img_wrap {
      position: relative;
      display: block;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: #fff;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        -webkit-transition: all 0.2s ease-in-out;
        -webkit-transform: scale(1);
        -ms-transition: all 0.2s ease-in-out;
        -ms-transform: scale(1);
        -moz-transition: all 0.2s ease-in-out;
        -moz-transform: scale(1);
        transition: all 0.2s ease-in-out;
        transform: scale(1);
        image-rendering: -webkit-optimize-contrast;
      }
    }
    .img_mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background-color: rgba(221, 221, 221, 0.5);
    }
  }
  .card_text {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: 180px;
    padding: 15px;
    overflow: hidden;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .card_title {
      overflow: hidden;
      display: block;
      font-size: 16px;
      line-height: 19px;
      color: #444;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .card_schedule {
      margin-top: 8px;
      overflow: hidden;
      display: block;
      height: 17px;
      font-size: 14px;
      line-height: 1.21;
      color: #999;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const title_tag_in = css`
  float: left;
`;
const eheaddiv_tag = css`
  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;
const title_tag_wrap = css`
  position: relative;
  float: left;
  height: 31px;
  width: 8px;
  display: block;
  .title_right_tag {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 100%;
    display: block;
    height: 15px;
    line-height: 15px;
    width: 28px;
    overflow: hidden;
    border-radius: 2px;
    background-color: #fb9f00;
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
`;

export {
  jp_card_list,
  list_card_type,
  detail_card_type,
  content_config_list,
  DetailCardLi,
  pass_goods_list,
  pass_goods_card,
  jp_comingsoon_card_list,
  list_comingsoon_card_type,
  title_tag_in,
  title_tag_wrap,
  eheaddiv_tag
};
