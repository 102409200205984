import React from "react";
import { createStore, applyMiddleware } from "redux";
import EmployCenterMainNew from "../containers/EmployCenterMainNew";
import EmployCenterBanner from "../containers/EmployCenterBanner";
import EmployCenterComingSoonList from "../containers/EmployCenterComingSoonList";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider } from "react-redux";
import Reducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const EmployCenterIndex = props => {
  const { title, component, option } = props.employInfo;
  const store = createStore(
    Reducer,
    {
      EmployCenterGet: props.employInfo
    },
    applyMiddleware(thunk, sagaMiddleware)
  );
  sagaMiddleware.run(rootSaga);
  const containerSelect = () => {
    console.log("component", component);
    switch (component) {
      /*case "MAIN":
        return <EmployCenterMain />;
      case "EMPLOY_MODAL":
        return <EmployCenterModal option={option} />;
      case "EMPLOY_PAY_MODAL":
        return <EmployCenterPayModal option={option} />;
      case "EMPLOY_PACKAGE_STATUS":
        return <EmployCenterPackageStatus title={title} option={option} />;
      case "EMPLOY_PASS_STATUS":
        return <EmployCenterPassStatus title={title} option={option} />;
      case "EMPLOY_PASS_SHOP":
        return <EmployCenterPassShop title={title} option={option} />;*/
      case "EMPLOY_BANNER":
        return <EmployCenterBanner title={title} option={option} />;
      case "EMPLOY_LIST":
        return <EmployCenterMainNew title={title} option={option} />;
      case "EMPLOY_COMING_SOON":
        return <EmployCenterComingSoonList title={title} option={option} />;/*
      case "EMPLOY_DETAIL":
        return <EmployCenterDetail title={title} option={option} />;
      case "EMPLOY_DETAIL_LIST":
        return <EmployCenterDetailList title={title} option={option} />;
      case "EMPLOY_DETAIL_BUY_BAR":
        return <EmployCenterBuyBar title={title} option={option} />;
      case "EMPLOY_MEMBERSHP":
        return <EmployCenterMembership title={title} option={option} />;*/
      default:
        return "";
    }
  };
  return <Provider store={store}>{containerSelect()}</Provider>;
};
export default EmployCenterIndex;
