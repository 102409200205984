import { delay } from "redux-saga";
import { take, all, put, select, call, takeLatest, fork } from "redux-saga/effects";
import api from "../../Helper/axiosGet";
import { isEmpty } from "../../Helper/gm";
import $ from 'jquery';
function* watchAndLog() {
  while (true) {
    const action = yield take("*");
    const state = yield select();
  }
}
function* workerSaga(option) {
  try {
    /*console.log("workSaga option ",option);*/
    /*api콜*/
    const response = yield call(api, option.option);
    /*console.log("api response",response);*/
    /*console.log("put",{ type: option.name, addBy: response.data.data });*/
    if(response.data.code === 500){
      console.log('500 Error');
      window.location = '/500_1';
    }

    if(option.hasOwnProperty('hide_spinner')) {
      yield put({ type: option.name, addBy: Object.assign(response.data.data, {hide_spinner: option.hide_spinner}) });
    } else {
      yield put({ type: option.name, addBy: response.data.data });
    }
  } catch (error) {
    console.log('workerSaga error',error);
  }
}

function* workerSagaList(option) {
  try {
    /*console.log("workSaga option ",option);*/

    const state = yield select();
    const {spinner} = state.EmployCenterGet.option.trigger_data;

    $(spinner).addClass('show');

    /*api콜*/
    const response = yield call(api, option.option);

    yield call(delay, 500);
    /*console.log("api response",response);*/
    yield put({
      type: option.name,
      addBy: {
        items: isEmpty(state.EmployCenterGet.next_items)
          ? state.EmployCenterGet.items
          : [...state.EmployCenterGet.items, ...state.EmployCenterGet.next_items],
        next_items: response.data.data.items,
        next_page: response.data.data.next_page
      }
    });

    $(spinner).removeClass('show');
  } catch (error) {
    console.log('workerSaga error',error);
  }
}

export function* rootSaga() {
  yield all([
    yield takeLatest("EMPLOY_CENTER_CALL", workerSaga),
    yield takeLatest("EMPLOY_CENTER_LIST_CALL", workerSagaList),
  ]);
}
