import React, {Component} from 'react';
import EmployCenterIndex from '../../components/EmployCenter/startup/EmployCenterIndex';

class NoMatch extends Component {
  render() {
    return (
      <div>
        없는 주소에요~ ^^
      </div>
    );
  }
}

export default NoMatch;
