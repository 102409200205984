import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import RoundSpinner from "./RoundSpinner";
import $ from 'jquery';
import _ from 'underscore';

import {
  SectionGroupDiv,
  EmploySectionDiv,
  EmployHeadDiv,
  EmployBodyDiv,
  InnerWrapDiv
} from "../styles/jpEmployLayout";

import ellipsis_one from "../styles/jpTextEllipsis";
import {
  jp_card_list,
  list_card_type,
  content_config_list
} from "../styles/jpCardType";

class EmployPackageList extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {}
  componentDidMount() {
    if (
      isEmpty(this.props.option.trigger_data) ||
      isEmpty(this.props.option.trigger_data.list_fnc)
    )
      return false;

    const scroll_fn = () => {
      if (
        $(window).scrollTop() >=
        $(document).height() -
          $(window).height() -
          $("#globalFooterWrap").outerHeight(true)
      ) {
        /*console.log("you're at the bottom of the page");*/
        this.props.listFnc();
      }
    };

    if ($("body").hasClass(this.props.option.trigger_data.page_class)) {
      $(window)
        .off("scroll.lecture_list")
        .on("scroll.lecture_list", _.debounce(scroll_fn, 100));
    }
  }

  renderSpinner() {
    if (
      isEmpty(this.props.option.trigger_data) ||
      isEmpty(this.props.option.trigger_data.list_fnc)
    )
      return false;
    return <RoundSpinner />;
  }

  loopList(items) {
    return items.map((d, i) => {
      const {
        description,
        id,
        item_type,
        payment,
        original_price,
        unit_price,
        recruitment,
        remain_date,
        report_count,
        sample,
        thumbnail,
        company,
        title,
        video_count,
        web_link_url
      } = d;
      return (
        <li
          key={`${this.props.name}_${i}`}
          className={`list_card_type ${list_card_type}`}
        >
          <a
            href={`${web_link_url}`}
            onClick={() => {

            }}
          >
            <div className="card_thumb">
              <div className="img_wrap">
                <img src={`${thumbnail}`} alt="이미지" />
              </div>
            </div>
            <div className="card_text">
              <div className="card_title">{title}</div>
              <div className={`card_summary ${ellipsis_one}`}>
                {isEmpty(company) ? "" : company.industry}
              </div>
            </div>
            <div className="card_footer">
              <span className={`time_remaining ${ellipsis_one}`}>
                {isEmpty(remain_date) ? "" : `${remain_date}`}
              </span>
              <ul className={`content_config_list ${content_config_list}`}>
                <li>
                  <span className="icon ico_lecture_vod">동영상</span>
                  {` ${video_count}개`}
                </li>
                <li>
                  <span className="icon ico_lecture_report">리포트</span>
                  {` ${report_count}개`}
                </li>
              </ul>
            </div>
          </a>
        </li>
      );
    });
  }

  loop() {
    if (this.props.items.length > 0) {
      return (
        <ul className={jp_card_list}>
          {this.loopList(this.props.items)}
          {this.loopList(this.props.next_items)}
        </ul>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <EmploySectionDiv>
        <EmployHeadDiv>
          <h3 className="title">{this.props.title}</h3>
          {isEmpty(this.props.more) ? (
            ""
          ) : (
            <a href={this.props.more} className="btn_more_list">
              더 보기
            </a>
          )}
        </EmployHeadDiv>
        <EmployBodyDiv>{this.loop()}</EmployBodyDiv>
        {this.renderSpinner()}
      </EmploySectionDiv>
    );
  }
}
const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
EmployPackageList = connect(
  getRow,
  brandApplyDispatchToProps
)(EmployPackageList);
export default EmployPackageList;
