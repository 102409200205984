import React, { Component } from 'react';
import { Home } from './views/home';
import { List } from './views/list';
import { NoMatch } from './views/nomatch';
import NavBar from "./components/NavBar";
import {Route, Switch, Redirect } from "react-router-dom";
import { PropsRoute, PublicRoute, PrivateRoute } from 'react-router-with-props';
import { withCookies } from 'react-cookie';

class Routes extends Component {

  constructor(props){
    super(props);
    console.log(props.cookies, props.cookies.get('name') && 'Ben')
    this.state = {
      SplitList: null,
      authed: false
    };
  }

  loginOk(){
    this.setState({
      authed: true
    });
    console.log(this.state)
  }

  render(){
    const { SplitList } = this.state;
    return (
      <div>
        <NavBar />
        <button type="button" onClick={()=>{this.loginOk()}}>로그인</button>
        <Switch>
          <PublicRoute exact path="/Home" component={Home}  cookies = {this.props.cookies} />
          <PrivateRoute path="/List" component={List} authed={this.state.authed}  redirectTo="/Home" cookies = {this.props.cookies}/>
          <Route component={NoMatch} />
        </Switch>
      </div>
    )
  }
}

export default withCookies(Routes);;