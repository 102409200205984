let isEmpty = function(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};
let autoCompleteCheck = function(d, t) {
  /*내용이 있을 때*/
  if (t.length !== 0) {
    if (d === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export {
  isEmpty,
  autoCompleteCheck
};
