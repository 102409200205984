import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import styled, { css } from "react-emotion";
import $ from 'jquery';
import _ from 'underscore';

import {
  EmployBodyDiv
} from "../styles/jpEmployLayout";

class RowBanner extends Component {
  constructor(props) {
    super(props);
  }
  loop() {
    if (this.props.items.map.length > 0) {
      return this.props.items.map((d, i) => {
        const { id, index, item_type, link, title, image_url, background_color } = d;
        return (
          <div
            key={`${this.props.name}_${i}`}
            className={banner_1004}
            style={{backgroundColor: background_color}}>
            <a href={link} onClick={ () => {  } }>
              <img src={image_url.web} alt={title} />
            </a>
          </div>
        );
      });
    } else {
      return "";
    }
  }
  render() {
    return <EmployBodyDiv>{this.loop()}</EmployBodyDiv>;
  }
}

const banner_1004 = css `
  overflow: hidden;
  width: 1004px;
  height: 120px;
  margin: 40px auto 0;
  background-color: #fff;
  img {
    width: 100%;
    height: auto;
    vertical-align: top;
    -webkit-transition: all 0.2s ease-in-out;
    -webkit-transform: scale(1);
    -ms-transition: all 0.2s ease-in-out;
    -ms-transform: scale(1);
    -moz-transition: all 0.2s ease-in-out;
    -moz-transform: scale(1);
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    image-rendering: -webkit-optimize-contrast;
  }
  a:hover {
    img {
      transition-duration: 0.2s;
      -webkit-transform: scale(1.03);
      -ms-transform: scale(1.03);
      -moz-transition: scale(1.03);
      transform: scale(1.03);
    }
  }
`;

const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
RowBanner = connect(
  getRow,
  brandApplyDispatchToProps
)(RowBanner);
export default RowBanner;
