import styled, { css } from "react-emotion";

const ellipsis_one = css`
  overflow: hidden;
  word-break: break-all;
  word-wrap: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ellipsis_multiline = css`
  overflow: hidden;
  display: block;
  display: -webkit-box;
  word-break: break-all;
  word-wrap: normal;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ellipsis_two = css`
  ${ellipsis_multiline} -webkit-line-clamp: 2;
`;

const ellipsis_three = css`
  ${ellipsis_multiline} -webkit-line-clamp: 3;
`;

export default ellipsis_one;
export { ellipsis_two, ellipsis_three };
