import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/jply_employ_center.css';
import Routes from './routes';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';


ReactDOM.render(
  <CookiesProvider>
    <Router>
      <Routes />
    </Router>
  </CookiesProvider>
  , document.getElementById('employcenter')
)



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
