import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import styled, { css } from "react-emotion";

import {
  SectionGroupDiv,
  EmploySectionDiv,
  EmployHeadDiv,
  EmployBodyDiv,
  InnerWrapDiv
} from "../styles/jpEmployLayout";

import ellipsis_one from "../styles/jpTextEllipsis";
import {
  jp_card_list,
  list_card_type,
  content_config_list,
  pick_banner,
  pick_banner_list
} from "../styles/jpCardType";

class BigBanner extends Component {
  constructor(props) {
    super(props);
  }
  loop() {
    if (this.props.items.map.length > 0) {
      return (
        <ul className={pick_banner_list_style}>
          {this.props.items.map((d, i) => {
            const { id, index, item_type, link, title, deep_link_url, image_url, background_color } = d;
            return (
              <li
                key={`${this.props.name}_${i}`}
                className="pick_banner"
                style={{backgroundColor: background_color}}>
                <a href={link} onClick={ () => {  } }>
                  <img src={image_url.web} alt={title} />
                </a>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return "";
    }
  }
  render() {
    return (
      <EmploySectionDiv>
        <EmployHeadDiv>
          <h3 className="title">{this.props.title}</h3>
        </EmployHeadDiv>
        <EmployBodyDiv>{this.loop()}</EmployBodyDiv>
      </EmploySectionDiv>
    );
  }
}

const pick_banner_list_style = css`
  width: 1004px;
  .pick_banner {
    overflow: hidden;
    width: 492px;
    height: 344px;
    background-color: #fff;
    &:nth-of-type(odd) {
      float: left;
    }
    &:nth-of-type(even) {
      float: right;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: top;
      -webkit-transition: all 0.2s ease-in-out;
      -webkit-transform: scale(1);
      -ms-transition: all 0.2s ease-in-out;
      -ms-transform: scale(1);
      -moz-transition: all 0.2s ease-in-out;
      -moz-transform: scale(1);
      transition: all 0.2s ease-in-out;
      transform: scale(1);
      image-rendering: -webkit-optimize-contrast;
    }
    a:hover {
      img {
        transition-duration: 0.2s;
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        -moz-transition: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
  &:after {
    display: block;
    content: '';
    clear: both;
  }
`;

const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
BigBanner = connect(
  getRow,
  brandApplyDispatchToProps
)(BigBanner);
export default BigBanner;
