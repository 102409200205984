import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import BigBanner from "../components/BigBanner";
import RowBanner from "../components/RowBanner";
import FullLineBanner from "../components/FullLineBanner";
import $ from 'jquery';
import _ from 'underscore';

class EmployCenterBanner extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.SagaApiCall({
      type: "EMPLOY_CENTER_CALL",
      name: "EMPLOY_CENTER_OK",
      option: this.props.option
    });
  }
  bannerPick(type) {
    /*console.log(type);*/
    switch (type) {
      case 2:
        return (
          <BigBanner
            name={`${this.props.option.params.list_type}${
              this.props.option.params.view_count
            }${this.props.option.params.current_page}`}
            title={
              isEmpty(this.props.title)
                ? ""
                : this.props.title
            }
            items={
              isEmpty(this.props.EmployCenterGet.items)
                ? []
                : this.props.EmployCenterGet.items
            }
          />
        );
      case 3:
        return (
          <FullLineBanner
            name={`${this.props.option.params.list_type}${
                this.props.option.params.view_count
              }${this.props.option.params.current_page}`}
            items={
              isEmpty(this.props.EmployCenterGet.items)
                ? []
                : this.props.EmployCenterGet.items
            }
          />
        );
      case 4:
        return (
          <RowBanner
            name={`${this.props.option.params.list_type}${
              this.props.option.params.view_count
            }${this.props.option.params.current_page}`}
            items={
              isEmpty(this.props.EmployCenterGet.items)
                ? []
                : this.props.EmployCenterGet.items
            }
          />
        );
      default:
        return <div>베너가 오류</div>;
    }
  }
  componentWillUnmount() {}
  render() {
    return this.bannerPick(this.props.option.params.banner_type);
  }
}

const getRow = state => state;
const applyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
EmployCenterBanner = connect(
  getRow,
  applyDispatchToProps
)(EmployCenterBanner);

export default EmployCenterBanner;
