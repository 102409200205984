import axios from "axios";
export default function fetchDog(option) {
    return axios({
        timeout: 10000,
        baseURL: 'https://plooto.jobplanet.co.kr',
        method: "get",
        url: option.url,
        params: Object.assign({}, option.params),
        headers: {
            Accept: 'application/json',
            user_name: "brain",
            password: "commerce$$"
        },
        withCredentials: true
    });
}
