import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from "react-emotion";

class Spinner extends Component {
  constructor(props) {
    super(props);
  }
  render(){
    return (
      <div id={this.props.id} className={`${loading_spinner} inline_spinner`}>
        <div className="loading_spinner_img bouncing-loader">
          <div className="bouncing-loader__round"></div>
          <div className="bouncing-loader__round"></div>
          <div className="bouncing-loader__round"></div>
        </div>
        <div className="loading_spinner_bg"></div>
      </div>
    )
  }
}

const loading_spinner = css`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 100000;

  .loading_spinner_img {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -40px;
    z-index: 9998;
  }
  .loading_spinner_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .4;
    z-index: 9997;
  }

  .bouncing-loader {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;

    &__round {
      width: 20px;
      height: 20px;
      background-color: #3C8787;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: 10px;
      }

      animation: bounce_spinner 0.6s infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

`;

export default Spinner;