import { combineReducers } from "redux";

let EmployCenterGet = (state = {}, action) => {
  switch (action.type) {
    case "EMPLOY_CENTER_OK":
    /*console.log("reducer>>",Object.assign({}, state, action.addBy));*/
      return Object.assign({}, state, action.addBy);
    default:
      return state;
  }
};

const Reducer = combineReducers({
  EmployCenterGet
});
export default Reducer;
