import React from 'react';
import { withRouter, NavLink, matchPath } from 'react-router-dom';

const NavBar = withRouter((props) => {
  const { cookies } = props;
  return (
    <div className="jp_lnb sticky">
      <div className="inner_grid">
        <div className="navigatior_wrap">
          <div className="table_row">
            <NavLink className="nav_item" activeClassName="active" to="/Home"><span className="tit">홈</span></NavLink>
            <NavLink className="nav_item" activeClassName="active" to="/List"><span className="tit">탐색</span></NavLink>
            <NavLink className="nav_item" activeClassName="active" to="/Shop"><span className="tit">이용권 구매</span></NavLink>
            <NavLink className="nav_item" activeClassName="active" to="/My"><span className="tit">내 강좌</span></NavLink>
          </div>
        </div>
      </div>
    </div>
  )
});

export default NavBar;