import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import styled, { css } from "react-emotion";
import $ from 'jquery';
import _ from 'underscore';

import {
  EmploySectionDiv
} from "../styles/jpEmployLayout";

class FullLineBanner extends Component {
  constructor(props) {
    super(props);
  }
  loop(){
    if(isEmpty(this.props.items)) return;

    return this.props.items.map(({
      id,
      index,
      item_type,
      link,
      title,
      image_url,
      background_color
    }) => {
      return (
        <LineBannerDiv
          key={`${this.props.name}`}
          background_color={background_color}>
          <a href={link} onClick={ () => {  } }>
            <img src={image_url.web} alt={title} />
          </a>
        </LineBannerDiv>
      );
    });
  }
  render() {
    return (
      <div>
        {this.loop()}
      </div>
    );
  }
}

const line_banner = props => css `
  width: 100%;
  height: 80px;
  background-color: ${props.background_color || '#fff' };
  & > a {
    display: block;
    width: 1004px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      width: auto;
      height: 100%;
      vertical-align: top;
    }
  }
`;

const LineBannerDiv = styled('div')`
  ${line_banner};
`;

const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
FullLineBanner = connect(
  getRow,
  brandApplyDispatchToProps
)(FullLineBanner);
export default FullLineBanner;
