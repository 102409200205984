import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import styled, { css } from "react-emotion";
import RoundSpinner from "./RoundSpinner";
import Spinner from "./Spinner";

import {
  SectionGroupDiv,
  EmploySectionDiv,
  EmployHeadDiv,
  EmployBodyDiv,
  InnerWrapDiv
} from "../styles/jpEmployLayout";

import ellipsis_one from "../styles/jpTextEllipsis";
import {
  jp_comingsoon_card_list,
  list_comingsoon_card_type,
  content_config_list,
  title_tag_in,
  title_tag_wrap,
  eheaddiv_tag
} from "../styles/jpCardType";

class EmployComingSonnCard extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {}
  componentDidMount() {}

  loopList(items) {
    return items.map((d, i) => {
      const {
        description,
        id,
        item_type,
        payment,
        sample,
        thumbnail,
        title,
        schedule
      } = d;
      return (
        <li
          key={`${this.props.name}_${i}`}
          className={`list_comingsoon_card_type ${list_comingsoon_card_type}`}
        >
          <div className="card_thumb">
            <div className="img_wrap">
              <img src={`${thumbnail}`} alt="이미지" />
            </div>
            <div className="img_mask" />
          </div>
          <div className="card_text">
            <div className="card_title">{title}</div>
            <div className="card_schedule">{schedule}</div>
          </div>
        </li>
      );
    });
  }

  loop() {
    if (this.props.items.length > 0) {
      return (
        <ul className={`jp_comingsoon_card_list ${jp_comingsoon_card_list}`}>
          {this.loopList(this.props.items)}
        </ul>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <EmploySectionDiv>
        <EmployHeadDiv className={`eheaddiv_tag ${eheaddiv_tag}`}>
          <h3 className={`title ${title_tag_in}`}>{this.props.title}</h3>
          <span className={`title_tag_wrap ${title_tag_wrap}`}>
            {isEmpty(this.props.tagbox) ? (
              ""
            ) : (
              <span className="title_right_tag">{this.props.tagbox}</span>
            )}
          </span>
        </EmployHeadDiv>
        <EmployBodyDiv>{this.loop()}</EmployBodyDiv>
      </EmploySectionDiv>
    );
  }
}
const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
EmployComingSonnCard = connect(
  getRow,
  brandApplyDispatchToProps
)(EmployComingSonnCard);
export default EmployComingSonnCard;
