import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from "react-emotion";

class RoundSpinner extends Component {
  constructor(props) {
    super(props);
  }
  render(){
    return (
      <div className={`round_spinner ${round_ring_spinner}`}><div></div><div></div><div></div><div></div></div>
    )
  }
}

const round_ring_spinner = css`
  position: absolute;
  left: 50%;
  bottom: 0;
  display: none;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 43px;
    height: 43px;
    margin: 5px;
    border: 5px solid transparent;
    border-radius: 50%;
    animation: round_ring_spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #999 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

export default RoundSpinner;