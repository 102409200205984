import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../Helper/gm";
import EmployComingSoonCard from "../components/EmployComingSoonCard";

class EmployCenterComingSoonList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  componentDidUpdate() {}
  componentDidMount() {
    this.props.SagaApiCall({
      type: "EMPLOY_CENTER_CALL",
      name: "EMPLOY_CENTER_OK",
      option: this.props.option
    });
  }
  componentWillUnmount() {}
  render() {
    return (
      <div>
        <EmployComingSoonCard
          name={`${this.props.option.params.list_type}${
            this.props.option.params.view_count
          }${this.props.option.params.current_page}`}
          tagbox={isEmpty(this.props.option.tag) ? "" : this.props.option.tag}
          title={
            isEmpty(this.props.EmployCenterGet.header) ||
            this.props.option.title === "none"
              ? ""
              : this.props.EmployCenterGet.header
          }
          items={
            isEmpty(this.props.EmployCenterGet.items)
              ? []
              : this.props.EmployCenterGet.items
          }
        />
      </div>
    );
  }
}

const getRow = state => state;
const brandApplyDispatchToProps = dispatch => ({
  SagaApiCall: v => dispatch(v),
  SagaApi: type => dispatch({ type })
});
EmployCenterComingSoonList = connect(
  getRow,
  brandApplyDispatchToProps
)(EmployCenterComingSoonList);
export default EmployCenterComingSoonList;
