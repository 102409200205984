import styled, { css } from "react-emotion";

const SectionGroupDiv = styled("div")`
  width: 1004px;
  margin: 0 auto 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #b4b4b4;
`;

const EmploySectionDiv = styled("div")`
  width: 1004px;
  margin: 60px auto 0;
  margin-top: ${props => (props.first ? "50px" : "60px")};
`;

const EmployHeadDiv = styled("div")`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  .title {
    display: block;
    font-size: 26px;
    color: #444;
    font-weight: bold;
    line-height: 31px;
    word-break: keep-all;
  }
  .title_emph_text {
    color: #00c362;
  }
  ${
    props => props.subText &&
    `
    &:after {
      display: block;
      content: '';
      clear: both;
    }
    .title {
      float: left;
    }
    .sub_text {
      float: left;
      display: inline-block;
      padding-top: 5px;
      margin-left: 20px;
      font-size: 16px;
      color: #999;
    }
    `
  }
  .btn_more_list {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #1e86ff;
    font-size: 18px;
    line-height: 21px;
    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
`;

const EmployBodyDiv = styled("div")`
  width: 100%;
  margin-top: ${props => props.mgtop};
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  .jp_status_tb {
    display: table;
    box-sizing: border-box;
    width: 492px;
    height: 69px;
    border: 1px solid #e6e6e6;
  }
  .status_tr {
    display: table-row;
  }
  .status_th,
  .status_td {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    color: #444;
  }
  .status_th {
    width: 114px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    background-color: #fbfbfc;
    border-right: 1px solid #ebecee;
    word-wrap: break-word;
    word-break: keep-all;
    text-align: center;
  }
  .status_td {
    &.discount {
      padding-right: 6px;
      font-size: 18px;
      color: #999;
    }
    &.price {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 24px;
      color: #333;
    }
    &.percent {
      width: 65px;
      font-size: 24px;
      color: #ff8400;
    }
  }
  .status_td {
    background: #fff;
    &:first-of-type {
      padding-left: 38px;
    }
    &:last-of-type {
      padding-right: 15px;
    }
    &.btn_cell {
      box-sizing: border-box;
      width: 150px;
      padding-left: 15px;
      padding-right: 15px;
    }
    em {
      font-weight: bold;
    }
  }
`;

const InnerWrapDiv = styled("div")`
  position: relative;
  width: 1004px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export {
  SectionGroupDiv,
  EmploySectionDiv,
  EmployHeadDiv,
  EmployBodyDiv,
  InnerWrapDiv
};
